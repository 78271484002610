<template>
  <v-autocomplete
    v-model="selectedRoles"
    :loading="loading"
    :items="roles"
    item-text="display_name"
    item-value="id"
    label="Roller"
    @blur="() => $emit('update:selectedRoles', selectedRoles)"
    multiple
    dense
  />
</template>

<script>
export default {
  name: "SMRoleSelectbox",
  props: {
    selectedRoles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roles: [],
      loading: true,
    };
  },
  async created() {
    try {
      this.roles = await this.fetchRoles();
      this.loading = false;
    } catch (error) {
      this.$toast.error(error.data.message);
    }
  },
  methods: {
    fetchRoles() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get("/roles")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
  },
};
</script>
