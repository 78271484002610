<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2>Kullanıcı Listesi</h2>
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-items-center">
        <LogModal log_name="user">
          <template v-slot:button>
            <v-icon dark color="cursor-pointer" class="mr-2">
              mdi-history
            </v-icon>
          </template>
        </LogModal>
        <v-icon
          color="error cursor-pointer"
          class="mr-2"
          dark
          v-permission="{
            permissions: ['users-delete'],
          }"
          v-if="!isProduction"
          :disabled="selected.length !== 1"
          @click="showDeleteUserModal"
        >
          mdi-delete
        </v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cursor-pointer"
              v-bind="attrs"
              dark
              @click="() => fetchUsers()"
              v-on="on"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          dark
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          append-icon="search"
        ></v-text-field>
        <CreateUserModal>
          <v-btn
            elevation="0"
            v-permission="{
              permissions: ['users-create'],
            }"
            color="success"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <v-icon small>mdi-plus</v-icon>
            Yeni Kullanıcı
          </v-btn>
        </CreateUserModal>
      </div>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-app-bar color="indigo" elevation="0" height="40px" dark>
      <v-tabs v-model="selectedTab" align-with-title>
        <v-tabs-slider color="primary lighten-4"></v-tabs-slider>

        <v-tab v-for="item in tabs" :key="item.value">
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-card-text>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="item in tabs" :key="item">
          <component
            :is="item.component"
            :search="search"
            :loading="loading"
            :isProduction="isProduction"
            :selected="selected"
            @update:selected="(val) => (selected = val)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { FETCH_USERS, REMOVE_USER } from "@/core/services/store/users.module";
import { defineComponent } from "vue";
import LogModal from "@/view/content/modals/LogModal.vue";

import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import CreateUserModal from "./CreateUserModal.vue";
import DataTable from "./table/DataTable.vue";
import Employees from "./table/Employees.vue";
import Students from "./table/Students.vue";

export default defineComponent({
  name: "UserList",
  components: {
    CreateUserModal,
    DataTable,
    Employees,
    Students,
    KTTopMenu,
    LogModal,
  },
  data() {
    return {
      search: "",
      selected: [],
      loading: true,
      tabs: [
        { text: "Tüm Kullanıcılar", value: "all", component: "DataTable" },
        {
          text: "Yetkili Kullanıcılar",
          value: "employees",
          component: "Employees",
        },
        { text: "Öğrenciler", value: "students", component: "Students" },
      ],
      selectedTab: "all",
    };
  },
  beforeMount() {
    this.fetchUsers().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions([FETCH_USERS, REMOVE_USER]),
    async acceptDelete() {
      try {
        await this.removeUser(this.selected[0]);
        this.$toast.success("Kullanıcı başarıyla silindi", {
          position: "bottom-right",
          duration: 3000,
        });
        this.selected = [];
      } catch (error) {
        this.$toast.error(
          "Bu kullanıcıya ait lead bilgileri bulunduğundan bu kullanıcıyı silemessiniz.",
          {
            position: "bottom-right",
            duration: 3000,
          }
        );
      }
    },
    showDeleteUserModal() {
      const params = {
        title: "Seçtiğiniz kullanıcı silinecek",
        text: "Seçili kullanıcıyı silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptDelete();
        },
      };
      this.$approvalModal.show(params);
    },
  },
  computed: {
    isProduction() {
      return process.env.NODE_ENV === "production";
    },
  },
  watch: {
    selectedTab() {
      this.selected = [];
    },
  },
});
</script>

<style lang="scss">
$data-table-dense-row-height: 15px;

.v-data-table {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          height: $data-table-dense-row-height;
          th {
            padding: 0px 2px 0px 2px;
          }
        }
      }
      tbody {
        tr {
          height: $data-table-dense-row-height;
          td {
            padding: 0px 0px 0px 0px;
            vertical-align: center;
            align-content: center;
            height: 15px;
          }
        }
      }
    }
  }
}
</style>
