<template>
  <fragment>
    <v-row class="mt-2" justify="center">
      <v-col cols="11">
        <v-text-field
          v-model.lazy.trim="user.name"
          hide-details="auto"
          label="Ad Soyad"
          validate-on-blur
          dense
          :rules="[
            (v) => !!v || 'Ad Soyad alanı boş bırakılamaz',
            (v) =>
              v?.length >= 3 ||
              'Ad Soyad alanı en az 3 karakterden oluşmalıdır',
          ]"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="11">
        <SMEmailTextField
          :email="user.email"
          @update:email="(email) => (user.email = email)"
          :user_id="user.id"
        ></SMEmailTextField>
      </v-col>
      <v-col v-if="!disablePassword" cols="11">
        <v-text-field
          v-model.lazy="user.password"
          :rules="[
            (v) => !!v || 'Parola alanı boş bırakılamaz',
            (v) =>
              v?.length >= 8 || 'Parola alanı en az 8 karakterden oluşmalıdır',
          ]"
          label="Parola"
          type="password"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="11">
        <SMPhoneSelectbox
          validatable
          :phone="user.phone"
          :validate-id="null"
          @update:phone="(phone) => (user.phone = phone)"
        ></SMPhoneSelectbox>
      </v-col>
      <v-col cols="11">
        <SMRoleSelectBox
          :selectedRoles="user.roles"
          @update:selectedRoles="(roles) => (user.roles = roles)"
        />
      </v-col>
      <!-- <v-col cols="11">
        <SMPermissionSelectBox
          :selectedPermissions="user.permissions"
          @update:selectedPermissions="
            (permissions) => (user.permissions = permissions)
          "
        />
      </v-col> -->
    </v-row>
  </fragment>
</template>

<script>
import { defineComponent } from "vue";
import SMPhoneSelectbox from "@/view/content/siliconmade/SM-Phone-Selectbox.vue";
import SMRoleSelectBox from "@/view/content/siliconmade/SM-Role-Selectbox.vue";
import SMPermissionSelectBox from "@/view/content/siliconmade/SM-Permission-Selectbox.vue";
import SMEmailTextField from "@/view/content/siliconmade/SM-Email-Textfield.vue";

export default defineComponent({
  name: "CreateQuickUser",
  components: {
    SMPhoneSelectbox,
    SMRoleSelectBox,
    SMPermissionSelectBox,
    SMEmailTextField,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
    disablePassword: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.disablePassword) {
      this.user.email = this.user.email.email || null;
    }
  },
  watch: {
    user: {
      handler() {
        this.$emit("update:user", this.user);
      },
      deep: true,
    },
  },
});
</script>
