<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" max-height>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-inline" v-bind="attrs" v-on="on">
          <slot name="button"></slot>
        </div>
      </template>
      <Card :title="title" modal :tabs="tabs" :defaultTab="defaultTab">
        <template #actions>
          <v-text-field
            @input="searchLogs"
            v-model="search"
            class="mx-2 mt-0 pt-0 search-input"
            hide-details="auto"
            clearable
            placeholder="Kayıtlarda Ara"
            append-icon="mdi-magnify"
            dense
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="getLogs">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Yenile</span>
          </v-tooltip>
        </template>
        <template #close>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template #item.descriptions>
          <LogDescription
            :logs="logs"
            :loading="loading"
            :pagination="pagination"
            @get-logs="getLogs($event)"
          />
        </template>
        <template #item.table>
          <LogTable
            :logs="logs"
            :loading="loading"
            :pagination="pagination"
            @get-logs="getLogs($event)"
          />
        </template>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Card from "@/view/content/components/Card";
import AxiosInstance from "@/core/services/axios.service";
import LogDescription from "../components/logs/LogDescription.vue";
import LogTable from "../components/logs/LogTable.vue";

export default defineComponent({
  name: "LogModal",
  components: {
    Card,
    LogDescription,
    LogTable,
  },
  props: {
    log_name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      tabs: [
        {
          value: "descriptions",
          text: "İşlem Açıklamaları",
        },
        {
          value: "table",
          text: "İşlem Kayıtları",
        },
      ],
      defaultTab: 0,
      loading: false,
      pagination: {
        last_page: 0,
        per_page: 0,
        current_page: 1,
        total: 0,
      },
      search: "",
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    getLogs(currentPage = this.pagination.current_page) {
      this.loading = true;
      AxiosInstance.get(`/logs`, {
        params: {
          log_name: this.log_name,
          page: currentPage,
          search: this.search,
        },
      })
        .then(({ data }) => {
          this.pagination = {
            last_page: data.last_page,
            per_page: data.per_page,
            current_page: data.current_page,
            total: data.total,
          };
          this.logs = data.data;
        })
        .catch(() => {
          this.$toast.error(
            "İşlem kayıtları getirilirken bir hata oluştu. Lütfen yenile butonuna tıklayarak tekrar deneyiniz.",
            {
              duration: 2000,
              position: "bottom-right",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchLogs() {
      // Wait for 2 seconds before performing the search operation
      clearTimeout(this.searchTimeout);
      this.loading = true;
      this.searchTimeout = setTimeout(() => {
        // Perform the desired operation here
        this.logs = [];
        this.getLogs();
        this.loading = false;
      }, 1000);
    },
  },
  computed: {
    title() {
      return "Geçmiş Kayıtlar";
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getLogs();
      }
    },
  },
});
</script>

<style scoped>
.word-wrap {
  word-wrap: break-word;
}

.search-input {
  width: 50px;
}
</style>
