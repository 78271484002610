<template>
  <v-autocomplete
    v-model="selectedPermissions"
    :loading="loading"
    :items="permissions"
    item-text="display_name"
    item-value="id"
    label="İzinler"
    @blur="sendBackToParent"
    multiple
    dense
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon
            :color="selectedPermissions.length > 0 ? 'indigo darken-4' : ''"
          >
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Hepsini Seç </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index < 5"
        >{{ item.display_name }} <span v-if="index < 4">, </span></span
      >
      <span v-if="index === 5" class="grey--text text-caption">
        (+ {{ selectedPermissions.length - 1 }} tane daha)
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "SMRoleSelectbox",
  props: {
    selectedPermissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      permissions: [],
      loading: true,
    };
  },
  async created() {
    try {
      this.permissions = await this.fetchRoles();
      this.loading = false;
    } catch (error) {
      this.$toast.error(error.data.message);
    }
  },
  methods: {
    fetchRoles() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get("/permissions")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.selectedPermissions = [];
        } else {
          this.selectedPermissions = this.permissions.slice();
        }
      });
    },
    sendBackToParent() {
      this.$emit("update:selectedPermissions", this.selectedPermissions);
    },
  },
  computed: {
    selectedAll() {
      return this.selectedPermissions.length === this.permissions.length;
    },
    selectedSome() {
      return this.selectedPermissions.length > 0 && !this.selectedAll;
    },
    icon() {
      if (this.selectedAll) return "mdi-close-box";
      if (this.selectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
};
</script>
