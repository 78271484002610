<template>
  <v-dialog v-model="openDialog" max-width="650" persistent>
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" class="display-inline" v-on="on">
        <slot />
      </div>
    </template>
    <v-card>
      <v-toolbar color="indigo" dark>
        <v-toolbar-title>Kullanıcı Düzenle</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="isFormValid">
        <v-card-text>
          <QuickUser
            :isFormValid="isFormValid"
            :disablePassword="true"
            @update:user="(u) => (currentUser = u)"
            :user="currentUser"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-content-end">
          <v-btn color="error" class="mr-2" @click="close">İptal</v-btn>
          <v-btn
            color="primary"
            class="mr-8"
            :disabled="!isFormValid"
            @click="updateUser"
            >Kaydet</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import QuickUser from "@/view/content/users/informations/QuickUser.vue";

export default defineComponent({
  name: "EditUserModal",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    QuickUser,
  },
  data() {
    return {
      openDialog: false,
      isFormValid: true,
      currentUser: {},
    };
  },
  created() {
    this.currentUser = Object.assign({}, this.user);
  },
  methods: {
    close() {
      this.openDialog = false;
    },
    async updateUser() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      try {
        const user = await this.postUser();
        this.$store.commit("editUser", user);
        this.$toast.success("Kullanıcı başarıyla güncellendi.", {
          duration: 2000,
          position: "bottom-right",
        });
        this.close();
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    postUser() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .put(`/users/${this.user.id}/edit`, this.currentUser)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data.errors);
          });
      });
    },
  },
});
</script>
