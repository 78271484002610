<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="users"
    :search="search"
    :single-select="true"
    :loading="loading"
    :show-select="!isProduction"
    :items-per-page="100"
    sort-by="id"
    sort-desc="true"
    disable-sort
    item-key="id"
  >
    <template #item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <router-link
            v-if="!item.register_token"
            :to="{
              name: 'user-edit',
              params: { id: item.id },
              query: { editable: true },
            }"
          >
            <v-icon color="warning" small v-bind="attrs" v-on="on">
              mdi-pencil
            </v-icon>
          </router-link>
        </template>
        <span>Düzenle</span>
      </v-tooltip>
      <EditUserModal :user="item">
        <v-btn
          v-bind="$attrs"
          v-on="$listeners"
          icon
          v-if="item.register_token"
          color="warning"
          small
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </EditUserModal>
    </template>
    <template #item.name="{ item }">
      <router-link
        :to="{
          name: 'user-edit',
          params: { id: item.id },
        }"
      >
        {{ item.name }}
      </router-link>
    </template>
    <template #item.phone="{ item }">
      <a :href="'tel:' + item.phone">{{ formatPhoneInput(item.phone) }}</a>
    </template>
    <template #item.email="{ item }">
      <a :href="'mailto:' + item?.email?.email">{{ item?.email?.email }}</a>
    </template>
    <template #item.created_at="{ item }">
      {{ $moment(item.created_at).format("DD/MM/YYYY HH:mm:ss") }}
    </template>
  </v-data-table>
</template>

<script>
import { formatPhoneInput } from "../../../../core/helper";
import EditUserModal from "../EditUserModal.vue";
import { mapGetters } from "vuex";

export default {
  methods: { formatPhoneInput },
  components: { EditUserModal },
  data() {
    return {
      headers: [
        { text: "Kayıt Tarihi", value: "created_at" },
        { text: "İsim", value: "name" },
        { text: "Telefon", value: "phone" },
        { text: "E-Posta", value: "email" },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "100px",
          sortable: false,
        },
        { text: "", value: "data-table-select", align: "left" },
      ],
    };
  },
  props: {
    search: {
      type: String,
      default: "",
    },
    isProduction: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    selected() {
      this.$emit("update:selected", this.selected);
    },
  },
  computed: {
    ...mapGetters(["users"]),
  },
};
</script>
