var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fragment',[_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Ad Soyad","validate-on-blur":"","dense":"","rules":[
          (v) => !!v || 'Ad Soyad alanı boş bırakılamaz',
          (v) =>
            v?.length >= 3 ||
            'Ad Soyad alanı en az 3 karakterden oluşmalıdır',
        ],"required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.name"}})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('SMEmailTextField',{attrs:{"email":_vm.user.email,"user_id":_vm.user.id},on:{"update:email":(email) => (_vm.user.email = email)}})],1),(!_vm.disablePassword)?_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"rules":[
          (v) => !!v || 'Parola alanı boş bırakılamaz',
          (v) =>
            v?.length >= 8 || 'Parola alanı en az 8 karakterden oluşmalıdır',
        ],"label":"Parola","type":"password","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"11"}},[_c('SMPhoneSelectbox',{attrs:{"validatable":"","phone":_vm.user.phone,"validate-id":null},on:{"update:phone":(phone) => (_vm.user.phone = phone)}})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('SMRoleSelectBox',{attrs:{"selectedRoles":_vm.user.roles},on:{"update:selectedRoles":(roles) => (_vm.user.roles = roles)}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }