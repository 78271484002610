var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"single-select":true,"loading":_vm.loading,"show-select":!_vm.isProduction,"items-per-page":100,"sort-by":"id","sort-desc":"true","disable-sort":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!item.register_token)?_c('router-link',{attrs:{"to":{
            name: 'user-edit',
            params: { id: item.id },
            query: { editable: true },
          }}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Düzenle")])]),_c('EditUserModal',{attrs:{"user":item}},[(item.register_token)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"warning","small":""}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
        name: 'user-edit',
        params: { id: item.id },
      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.phone",fn:function({ item }){return [_c('a',{attrs:{"href":'tel:' + item.phone}},[_vm._v(_vm._s(_vm.formatPhoneInput(item.phone)))])]}},{key:"item.email",fn:function({ item }){return [_c('a',{attrs:{"href":'mailto:' + item?.email?.email}},[_vm._v(_vm._s(item?.email?.email))])]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY HH:mm:ss"))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }