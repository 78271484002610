<template>
  <v-card>
    <v-card-text>
      <div
        v-if="loading"
        style="height: 250px"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          :size="70"
          width="7"
        ></v-progress-circular>
        <h3 class="mt-5">Lütfen bekleyiniz...</h3>
      </div>
      <div v-else-if="logs.length > 0">
        <v-list
          v-if="!loading"
          class="overflow-auto"
          rounded
          max-height="400px"
        >
          <v-list-item-group>
            <v-list-item
              v-for="(log, index) in logs"
              :key="index"
              :value="log"
              class="bg-light-info"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ log.event }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="word-wrap">{{ log.description }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div
        v-else
        style="height: 250px"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <h3 class="mt-5">Kayıt Bulunamadı</h3>
      </div>

      <!-- Pagination -->
      <v-pagination
        v-if="logs.length > 0"
        v-model="pagination.current_page"
        :length="pagination.last_page"
        :total-visible="pagination.per_page"
        :server-items-length="pagination.total"
        :pagination.sync="pagination"
        @input="$emit('get-logs', pagination.current_page)"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    logs: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        last_page: 0,
        per_page: 0,
        current_page: 1,
        total: 0,
      }),
    },
  },
};
</script>
